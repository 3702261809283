export const MAP_DEFAULT_CENTER = [27.29837389409791, 17.508465945376724];
export const MAP_DEFAULT_ZOOM = 5;

export const MAP_DEFAULT_MAX_BOUNDS = [
  [19.85226849617444, 4.023556225647973],
  [36.26728081550756, 30.948477942841638]
]

export const NEW_REPORT = {
  wellCompliance: "",
  location: "",
  client: "",
  field: "",
  wellHeadSize: "",
  latitude: 0,
  longitude: 0,
};

export const STATUSES = {
  draft: "Draft",
  submit: "On review",
  approved: "Submitted",
  reject: "Rejected",

  "Draft": "draft",
  "On Review": "submit",
  "Submitted": "approved",
  "Rejected": "reject",
}

export const LIST_COLUMNS = [
  {
    key: "well",
    header: "Well",
  },
  {
    key: "field",
    header: "Field",
  },
  {
    key: "client",
    header: "Client",
  },
  {
    key: "createdDate",
    header: "Created Date",
  },
  {
    key: "reviewedBy",
    header: "Reviewed By",
  },
  {
    key: "createdBy",
    header: "Created By",
  },
  {
    key: "status",
    header: "Status",
  },
];

export const FIELD_LIST_COLUMNS = [
  {
    key: "name",
    header: "Name",
  },
  {
    key: "client",
    header: "Client",
  },
]

export const WELL_LIST_COLUMNS = [
  {
    key: "well",
    header: "well",
  },
  {
    key: "field",
    header: "field",
  },
  {
    key: "client",
    header: "Client",
  },
]

export const DECLINED_COLUMNS = [
  {
    key: "well",
    header: "Well",
  },
  {
    key: "field",
    header: "Field",
  },
  {
    key: "customer",
    header: "Customer",
  },
  {
    key: "revDate",
    header: "Reviewed date",
  },
  {
    key: "revBy",
    header: "Reviewed by",
  },
  {
    key: "term",
    header: "Term",
  },
];

export const ROLES = {
  client: "Client",
  wellHeadSuperVisor: "WHM",
  engineerOnCharge: "EIC"
}

export const MAP_MODE = {
  clients: "clients",
  fields: "fields",
  wells: "wells",
}